import React, { createContext, useEffect, useState } from "react";
import Head from "next/head";
import { AuthProvider } from "../src/AuthContext";
import { SocketStatusProvider } from "../src/SocketStatusContext";
import { OrderFilterProvider } from "../src/OrderFilterContext";
import { OrderProvider } from "../src/OrderContext";
import { DriverProvider } from "../src/DriverContext";
import { ChatProvider } from "../src/ChatContext";
import { SosProvider } from "../src/SosContext";
import { RoutesDirectionsProvider } from "../src/RoutesDirectionsContext";
import { ToastContainer } from "react-toastify";

import { ChakraProvider, extendTheme } from "@chakra-ui/react";

import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-toastify/dist/ReactToastify.css";

import "../styles/HeaderCard.css";
// Will move this after some time as module css
import "../styles/Compose.css";
import "../styles/ConversationList.css";
import "../styles/ConversationListItem.css";
import "../styles/Message.css";
import "../styles/MessageList.css";
import "../styles/Messenger.css";
import "../styles/Toolbar.css";
import "../styles/ToolbarButton.css";

import "../styles/PastTrips.css";

import "../styles/globals.css";
import "../styles/newOrder.css";
import "../styles/OrderDetailsNew.css";
import "../styles/NewHomePage.css";
// import { ApolloProvider } from "@apollo/client";
import { ApolloProviderWrapper } from "../lib/apollo";

export const GlobalContext = createContext([null, () => null]);

const theme = extendTheme({
  colors: {
    brand: {
      50: "#11a00d",
      100: "#11a00d",
      200: "#11a00d",
      300: "#11a00d",
      400: "#11a00d",
      500: "#11a00d",
      600: "#11a00d",
      700: "#11a00d",
      800: "#11a00d",
      900: "#11a00d",
    },
    lightGray: {
      50: "#eeeeee",
      100: "#eeeeee",
      200: "#eeeeee",
      300: "#eeeeee",
      400: "#eeeeee",
      500: "#eeeeee",
      600: "#eeeeee",
      700: "#eeeeee",
      800: "#eeeeee",
      900: "#eeeeee",
    },
    black: {
      50: "#000000",
      100: "#000000",
      200: "#000000",
      300: "#000000",
      400: "#000000",
      500: "#000000",
      600: "#000000",
      700: "#000000",
      800: "#000000",
      900: "#000000",
    },
  },
});

const App = ({ Component, pageProps }) => {
  const [domLoaded, setDomLoaded] = useState(false);
  const [globalData, setGlobalData] = useState(null);

  useEffect(() => {
    setDomLoaded(true);
  }, []);

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Dispatch Vert</title>
      </Head>
      <ApolloProviderWrapper>
        <AuthProvider>
          <GlobalContext.Provider value={[globalData, setGlobalData]}>
            <SocketStatusProvider>
              <ChatProvider>
                <DriverProvider>
                  <OrderFilterProvider>
                    <RoutesDirectionsProvider>
                      <SosProvider>
                        <OrderProvider>
                          <ChakraProvider theme={theme}>
                            <Component {...pageProps} />
                          </ChakraProvider>
                        </OrderProvider>
                      </SosProvider>
                    </RoutesDirectionsProvider>
                  </OrderFilterProvider>
                </DriverProvider>
              </ChatProvider>
            </SocketStatusProvider>
          </GlobalContext.Provider>
        </AuthProvider>
      </ApolloProviderWrapper>

      {domLoaded && (
        <ToastContainer
          position="top-right"
          autoClose={30000}
          style={{ zIndex: 1000000000 }}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss={false}
          draggable={false}
          pauseOnHover={false}
          theme="dark"
        />
      )}
    </>
  );
};

export default App;
